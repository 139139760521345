import axios from 'axios';
import { Cookies, useQuasar } from 'quasar';

// if (!process.env.SERVER) {
//     var baseURL = process.env.CLIENT
//         ? `${window.origin}/api/v4`
//         : window.origin.includes('alpha')
//             ? 'https://develop.api.etabl.ru/api/v4'
//             : 'https://api.etabl.ru/api/v4';
// }

/**
 * 
 * @param {string} method 
 * @param {string} url 
 * @param {object} args 
 * @returns 
 */
async function request(method, url, args = {}, customUrl, arrayData) {
    const config = {};

    let cookiesString = null;

    //config.cookies = useQuasar()?.cookies.getAll();

    /*
     На дебаг ошибки "inject() can only be used inside setup() or functional components"
    ==================================
     config.cookies2 = Cookies?.getAll();
     console.log(config.cookies2);
    ==================================
    */

    if(config.cookies) {
        cookiesString = Object.keys(config.cookies).map(key => key + '=' + config.cookies[key]).join(';');
    }

    if (args.headers) {
        config.headers = Object.assign({}, args.headers);
    }

    if (args.token) {
        config.headers = Object.assign({
            'AUTHORIZATION': `Bearer ${args.token.token_id}`,
        }, config.headers);
    }

    if (args.timeout) {
        config.timeout = args.timeout || 6e4;
    }

    let params = [];

    if (args.query) {
        params = new URLSearchParams();

        for (const [key, value] of Object.entries(args.query)) {
            if (typeof value == 'object') {
                if (value?.length) {
                    for (const q of value) {
                        params.append(key, q);
                    }
                }
            } else if (value || value == 0) {
                params.append(key, value);
            }
        }
    }

    if (params.length === 1 && Object.keys(config).length) {
        params.push({});
    }

    const dataParams = Object.assign({}, args.data);

    try {
        const { code, data, status } = await axios({
            method,
            url: customUrl || `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v4/${url}`,
            headers: {
                ...config.headers,
                ...( cookiesString ? { Cookies: cookiesString } : {}),
            },
            timeout: config.timeout,
            params,
            data: arrayData || dataParams,
        });

        return (status === 200 || status === 201)
            ? data
            : {};
    } catch (error) {
        if (process.env.DEV) {
            console.error(error);
        }

        throw error;
    }
}

export { request };
